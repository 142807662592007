import { Link } from "../ui/links";

export default function DisclosureBox() {
    const styles = {
        fontSize: "0.8em",
        color: "#666",
        fontStyle: "italic",
    };
    // TODO: decide if we're going to put affiliate links in the content itself. I'm not sure if we should do that.
    // "Note that this article may contain affiliate links."
    // "A free account gives you access to thousands of pages of free content about memory techniques and joins you in a community with tens of thousands of other people working on memory improvement."
    return (
        <div style={styles}>
            To remove ads,{" "}
            <Link href="https://forum.artofmemory.com/signup" isNofollow={true}>
                create a free account
            </Link>
            .
        </div>
    );
}
